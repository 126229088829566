<template>
  <div class="payment auth">
    <nav class="container">
      <div class="d-flex justify-content-between align-items-center mb-4 pt-4">
        <img src="@/assets/logo.png" />
        <div>
          <button class="btn btn-outline-light" @click="$user.logout">
            Sair
          </button>
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="row">
        <div class="col-md-7 col-lg-5 mx-auto">
          <form class="card" @submit.prevent="submit">
            <div class="card-body">
              <div class="step">Passo 3 de 3</div>

              <h3 class="text-center">Dados de pagamento</h3>
              <p class="small text-center mb-3">
                Por favor, informe os dados do seu cartão de crédito:
              </p>

              <div class="text-center mb-4 pb-1">
                <img src="@/assets/images/cards.png" />
              </div>

              <form-group
                v-model="name"
                id="name"
                type="text"
                placeholder="Nome como está no cartão"
                :errors="errors.holder_name"
                autocomplete="cc-name"
              />

              <form-group
                v-model="number"
                id="number"
                type="tel"
                placeholder="Número do cartão"
                :mask="'#### #### #### ####'"
                :errors="errors.number"
                autocomplete="cc-number"
              />

              <form-group
                v-model="date"
                id="date"
                type="tel"
                placeholder="Data de validade (MM/AA)"
                :mask="'##/####'"
                :errors="dateErrors"
                autocomplete="cc-exp"
              />

              <form-group
                v-model="cvv"
                id="cvv"
                type="tel"
                placeholder="Código de segurança (CVV)"
                :mask="'####'"
                :errors="errors.cvv"
              />

              <!-- <form-group
                v-model="cpf"
                id="cpf"
                type="tel"
                placeholder="CPF"
                :mask="'###.###.###-##'"
                :errors="errors.holder_document"
              /> -->

              <div class="text-center pt-3">
                <button
                  type="submit"
                  class="btn btn-success btn-lg btn-block"
                  :disabled="loading"
                >
                  <loading :show="loading">Ativar minha assinatura</loading>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import concat from 'lodash/concat'

export default {
  data () {
    return {
      loading: false,
      name: '',
      number: '',
      date: '',
      cvv: '',
      // cpf: '',
      errors: {
        number: [],
        holder_name: [],
        exp_month: [],
        exp_year: [],
        // holder_document: [],
        cvv: []
      }
    }
  },
  computed: {
    dateErrors () {
      return concat(
        get(this.errors, 'exp_month', []),
        get(this.errors, 'exp_year', [])
      )
    }
  },
  methods: {
    submit () {
      this.$message.hide()
      this.loading = true
      const month = this.date.slice(0, 2)
      const year = this.date.slice(-2)
      const coupon = window.localStorage.getItem('planref')

      this.$store
        .dispatch('user/card', {
          // document: this.cpf,
          number: this.number,
          name: this.name,
          month,
          year,
          cvv: this.cvv,
          coupon
        })
        .then(() => {
          this.$router.replace({ name: 'home' })
          this.$store.dispatch('postLogin')
        })
        .catch(error => {
          this.errors = error.response.data
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0])
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
